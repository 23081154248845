@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?ib7qja');
  src: url('fonts/icomoon.eot?ib7qja#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ib7qja') format('truetype'),
    url('fonts/icomoon.woff?ib7qja') format('woff'),
    url('fonts/icomoon.svg?ib7qja#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu3_eipo:before {
  content: '\ea15';
}
.icon-sync-contact:before {
  content: '\ea14';
}
.icon-widget_runningtrade:before {
  content: '\ea13';
  color: #969696;
}
.icon-menu3_academy:before {
  content: '\ea10';
  color: #666;
}
.icon-menu_academy:before {
  content: '\e9fa';
}
.icon-pdf-fullscreen:before {
  content: '\ea0d';
  color: #fff;
}
.icon-pdf-download:before {
  content: '\ea0e';
  color: #fff;
}
.icon-share:before {
  content: '\ea0c';
  color: #fff;
}
.icon-referral_code:before {
  content: '\ea0b';
}
.icon-form-edit:before {
  content: '\ea0a';
  color: #7a7a7a;
}
.icon-profile_post:before {
  content: '\ea04';
}
.icon-download:before {
  content: '\ea05';
  color: #00ab6b;
}
.icon-stream-post-tip:before {
  content: '\ea06';
  color: #6b6b6b;
}
.icon-stream-post-chat:before {
  content: '\ea09';
  color: #6b6b6b;
}
.icon-user-block:before {
  content: '\ea03';
  color: #000;
}
.icon-user-privacy:before {
  content: '\ea07';
}
.icon-link-chain:before {
  content: '\ea08';
}
.icon-securities_account_tradingprofile:before {
  content: '\ea00';
}
.icon-securities_account_publicprofile:before {
  content: '\ea01';
}
.icon-securities_account_publicprofilebig:before {
  content: '\ea02';
}
.icon-arrow-left:before {
  content: '\e9f9';
  color: #b5b5b5;
}
.icon-securities-card:before {
  content: '\e9f8';
}
.icon-securities-monitor:before {
  content: '\e9f7';
}
.icon-securities-lock:before {
  content: '\e9fb';
}
.icon-securities-key:before {
  content: '\e9fc';
}
.icon-securities-mobile:before {
  content: '\e9fd';
}
.icon-securities-message:before {
  content: '\e9fe';
}
.icon-securities-file:before {
  content: '\e9ff';
}
.icon-securities-security:before {
  content: '\ea0f';
}
.icon-password:before {
  content: '\e9f5';
  color: #a7a7a7;
}
.icon-user:before {
  content: '\e9f6';
  color: #a7a7a7;
}
.icon-post_icon-11:before {
  content: '\e9f4';
}
.icon-notification_tip:before {
  content: '\e9f3';
}
.icon-setting_tipjar:before {
  content: '\e9f1';
}
.icon-new-tip:before {
  content: '\e9f2';
}
.icon-chat-gif_polls:before {
  content: '\e9f0';
}
.icon-toolbar_information:before {
  content: '\e906';
}
.icon-toolbar_arrow-down:before {
  content: '\e9e5';
}
.icon-toolbar_arrow-up:before {
  content: '\e9e6';
}
.icon-toolbar_filter:before {
  content: '\e9e4';
}
.icon-menu_balance:before {
  content: '\e9ee';
}
.icon-menu_equity:before {
  content: '\e9ef';
}
.icon-menu_discover-stocks:before {
  content: '\e9eb';
}
.icon-menu_orders:before {
  content: '\e9ec';
}
.icon-menu_alert:before {
  content: '\e9e8';
}
.icon-menu_bandar:before {
  content: '\e9e9';
}
.icon-menu_discover-people:before {
  content: '\e9ea';
}
.icon-menu_portfolio:before {
  content: '\e9ed';
}
.icon-avatar_bell:before {
  content: '\e9e2';
}
.icon-avatar_order:before {
  content: '\e9e3';
}
.icon-panel_order:before {
  content: '\e9e1';
}
.icon-custom_bandar:before {
  content: '\e9e0';
}
.icon-profile_groupfill:before {
  content: '\e9de';
}
.icon-profile_target:before {
  content: '\e9db';
}
.icon-profile_stream:before {
  content: '\e9dc';
}
.icon-profile_group:before {
  content: '\e9dd';
}
.icon-toolbar_menu:before {
  content: '\e9df';
}
.icon-chat-gif_target:before {
  content: '\e9d8';
}
.icon-chat-gif_gif:before {
  content: '\e9d9';
}
.icon-chat-gif_smile:before {
  content: '\e9da';
}
.icon-menu3_logout:before {
  content: '\e9d3';
}
.icon-menu3_support:before {
  content: '\e9d4';
}
.icon-menu3_withdraw:before {
  content: '\e9d5';
}
.icon-menu3_deposit:before {
  content: '\e9d6';
}
.icon-menu3_password:before {
  content: '\e9d7';
}
.icon-tradesmall_deposit:before {
  content: '\e9ce';
}
.icon-tradesmall_logout:before {
  content: '\e9cf';
}
.icon-tradesmall_password:before {
  content: '\e9d0';
}
.icon-tradesmall_support:before {
  content: '\e9d1';
}
.icon-tradesmall_withdraw:before {
  content: '\e9d2';
}
.icon-toolbar_chat_check:before {
  content: '\e9ca';
}
.icon-toolbar_chat_doublecheck:before {
  content: '\e9cb';
}
.icon-toolbar_chat_error:before {
  content: '\e9cc';
}
.icon-toolbar_chat_pending:before {
  content: '\e9cd';
}
.icon-android_leaderboard:before {
  content: '\e9c8';
  color: #666;
}
.icon-android_money:before {
  content: '\e9c9';
  color: #6b6b6b;
}
.icon-android_chat:before {
  content: '\e9c3';
  color: #6b6b6b;
}
.icon-android_portfolio:before {
  content: '\e9c4';
  color: #6b6b6b;
}
.icon-android_search:before {
  content: '\e9c5';
  color: #6b6b6b;
}
.icon-android_stream:before {
  content: '\e9c6';
  color: #6b6b6b;
}
.icon-android_watchlist:before {
  content: '\e9c7';
  color: #6b6b6b;
}
.icon-setting_support:before {
  content: '\e9c2';
}
.icon-new_analytics:before {
  content: '\e9bf';
}
.icon-tradesmall_pencil2:before {
  content: '\e9c0';
}
.icon-tradesmall_pencil:before {
  content: '\e9c1';
}
.icon-menu3_account:before {
  content: '\e9bd';
}
.icon-tradesmall_account:before {
  content: '\e9be';
}
.icon-tradesmall_term2:before {
  content: '\e9ba';
}
.icon-tradesmall_term:before {
  content: '\e9bb';
}
.icon-menu3_term:before {
  content: '\e9bc';
}
.icon-menu3_history:before {
  content: '\e9b4';
}
.icon-menu3_order:before {
  content: '\e9b5';
}
.icon-menu3_portfolio:before {
  content: '\e9b6';
}
.icon-custom_question:before {
  content: '\e9b7';
}
.icon-custom_trash:before {
  content: '\e9ad';
}
.icon-menu3_leaderboard:before {
  content: '\e9b8';
}
.icon-tradesmall_leaderboard:before {
  content: '\e9b9';
}
.icon-new-likefill:before {
  content: '\e9b3';
}
.icon-new-comment:before {
  content: '\e9ae';
}
.icon-new-like:before {
  content: '\e9af';
}
.icon-new-share:before {
  content: '\e9b0';
}
.icon-tradesmall_switch:before {
  content: '\e9ac';
}
.icon-tradesmall_history:before {
  content: '\e9a8';
  color: #6b6b6b;
}
.icon-tradesmall_order:before {
  content: '\e9a9';
  color: #6b6b6b;
}
.icon-tradesmall_orderbook:before {
  content: '\e9aa';
  color: #6b6b6b;
}
.icon-tradesmall_portfolio:before {
  content: '\e9ab';
}
.icon-toolbar_pencil:before {
  content: '\e9a5';
}
.icon-menu_orderbook:before {
  content: '\e9a6';
}
.icon-menu3_orderbook:before {
  content: '\e9a7';
}
.icon-tradebig_history:before {
  content: '\e9a1';
  color: #6b6b6b;
}
.icon-tradebig_order:before {
  content: '\e9a2';
  color: #6b6b6b;
}
.icon-tradebig_orderbook:before {
  content: '\e9a3';
  color: #6b6b6b;
}
.icon-tradebig_portfolio:before {
  content: '\e9a4';
  color: #6b6b6b;
}
.icon-stream-likecircle:before {
  content: '\e9b1';
}
.icon-yongki_share:before {
  content: '\e9b2';
}
.icon-profile_calendar:before {
  content: '\e99a';
}
.icon-profile_like:before {
  content: '\e99b';
}
.icon-profile_location:before {
  content: '\e99c';
}
.icon-profile_people:before {
  content: '\e99d';
}
.icon-profile_reputation:before {
  content: '\e99e';
}
.icon-profile_view:before {
  content: '\e99f';
}
.icon-profile_website:before {
  content: '\e9a0';
}
.icon-trading_delete:before {
  content: '\e98c';
}
.icon-mobile_chat:before {
  content: '\e98e';
}
.icon-mobile_help:before {
  content: '\e98f';
}
.icon-mobile_invite:before {
  content: '\e990';
}
.icon-mobile_logout:before {
  content: '\e991';
}
.icon-mobile_setting:before {
  content: '\e992';
}
.icon-mobile_stream:before {
  content: '\e993';
}
.icon-mobile_alerts:before {
  content: '\e994';
}
.icon-mobile_orderbook:before {
  content: '\e995';
}
.icon-mobile_people:before {
  content: '\e996';
}
.icon-mobile_portfolio:before {
  content: '\e997';
}
.icon-mobile_stocks:before {
  content: '\e998';
}
.icon-mobile_watchlist:before {
  content: '\e999';
}
.icon-avatar_search:before {
  content: '\e98b';
}
.icon-avatar_alert:before {
  content: '\e988';
}
.icon-avatar_report:before {
  content: '\e989';
}
.icon-widget_report:before {
  content: '\e987';
}
.icon-toolbar_save:before {
  content: '\e98d';
}
.icon-toolbar_minus:before {
  content: '\e98a';
}
.icon-toolbar_valid:before {
  content: '\e986';
}
.icon-toolbar_pinned-fill:before {
  content: '\e97f';
}
.icon-toolbar_pinned:before {
  content: '\e97d';
}
.icon-toolbar_repost:before {
  content: '\e97e';
}
.icon-custom_verified-circle:before {
  content: '\e978';
}
.icon-custom_verified:before {
  content: '\e977';
}
.icon-custom_pie:before {
  content: '\e975';
}
.icon-feature_bandar:before {
  content: '\e980';
}
.icon-feature_charting:before {
  content: '\e981';
}
.icon-feature_foreign:before {
  content: '\e982';
}
.icon-feature_pricealert:before {
  content: '\e983';
}
.icon-feature_screener:before {
  content: '\e984';
}
.icon-feature_valuation:before {
  content: '\e985';
}
.icon-trading_star:before {
  content: '\e97b';
}
.icon-trading_star-fill:before {
  content: '\e97c';
}
.icon-trading_xcircle:before {
  content: '\e97a';
}
.icon-trading_share:before {
  content: '\e979';
}
.icon-trading_xbold:before {
  content: '\e976';
}
.icon-trading_back:before {
  content: '\e972';
}
.icon-trading_gear:before {
  content: '\e973';
}
.icon-trading_x:before {
  content: '\e974';
}
.icon-chartbit_indicator2:before {
  content: '\e971';
}
.icon-up-arrow:before {
  content: '\e965';
}
.icon-menu3_financials:before {
  content: '\e926';
}
.icon-menu3_trade:before {
  content: '\e966';
}
.icon-menu_trade:before {
  content: '\e967';
}
.icon-menu_keystat:before {
  content: '\e957';
}
.icon-menu_billing:before {
  content: '\e958';
}
.icon-menu_key:before {
  content: '\e959';
}
.icon-menu_logout:before {
  content: '\e95a';
}
.icon-menu_setting:before {
  content: '\e95b';
}
.icon-chat_send:before {
  content: '\e954';
}
.icon-chat_attach:before {
  content: '\e955';
}
.icon-chat_smile:before {
  content: '\e956';
}
.icon-menu_financials2:before {
  content: '\e941';
}
.icon-coba_coba:before {
  content: '\e918';
}
.icon-chat_group:before {
  content: '\e951';
}
.icon-chat_channel:before {
  content: '\e952';
}
.icon-shop_foreign:before {
  content: '\e917';
}
.icon-shop_bandar:before {
  content: '\e932';
}
.icon-shop_chartbit:before {
  content: '\e933';
}
.icon-shop_comparison:before {
  content: '\e934';
}
.icon-shop_consensus:before {
  content: '\e935';
}
.icon-shop_financial:before {
  content: '\e936';
}
.icon-shop_fundachart:before {
  content: '\e937';
}
.icon-shop_keystats:before {
  content: '\e938';
}
.icon-shop_screener:before {
  content: '\e939';
}
.icon-shop_seasonality:before {
  content: '\e93a';
}
.icon-compose_file:before {
  content: '\e95e';
}
.icon-compose_camera:before {
  content: '\e95c';
}
.icon-compose_clip:before {
  content: '\e95d';
}
.icon-compose_close:before {
  content: '\e95f';
}
.icon-compose_plus:before {
  content: '\e960';
}
.icon-stream_likefill:before {
  content: '\e911';
  color: #000;
}
.icon-stream_dislikefill:before {
  content: '\e912';
  color: #000;
}
.icon-stream_comment:before {
  content: '\e90f';
}
.icon-stream_dislike:before {
  content: '\e910';
}
.icon-stream_like:before {
  content: '\e915';
}
.icon-stream_reply:before {
  content: '\e91a';
}
.icon-stream_share:before {
  content: '\e91b';
}
.icon-chartbit_indicator:before {
  content: '\e970';
}
.icon-widget_alert:before {
  content: '\e96c';
}
.icon-widget_close:before {
  content: '\e953';
}
.icon-panel_inbox:before {
  content: '\e916';
}
.icon-panel_chat:before {
  content: '\e93b';
}
.icon-panel_notification:before {
  content: '\e93c';
}
.icon-notification_activity:before {
  content: '\e93d';
}
.icon-notification_back:before {
  content: '\e93e';
}
.icon-notification_mention:before {
  content: '\e93f';
}
.icon-notification_report:before {
  content: '\e940';
}
.icon-widget_bandar:before {
  content: '\e91c';
}
.icon-widget_chat:before {
  content: '\e91d';
}
.icon-widget_hotlist:before {
  content: '\e91e';
}
.icon-widget_stream:before {
  content: '\e91f';
}
.icon-widget_support:before {
  content: '\e920';
}
.icon-widget_valuation:before {
  content: '\e921';
}
.icon-widget_watchlist:before {
  content: '\e922';
}
.icon-menu_financials:before {
  content: '\e907';
}
.icon-menu3_ebook:before {
  content: '\e923';
}
.icon-menu3_calendar:before {
  content: '\e924';
}
.icon-menu3_earning:before {
  content: '\e925';
}
.icon-menu3_fundachart:before {
  content: '\e927';
}
.icon-menu3_glossary:before {
  content: '\e928';
}
.icon-menu3_screener:before {
  content: '\e929';
}
.icon-menu3_seasonality:before {
  content: '\e92a';
}
.icon-menu3_sector:before {
  content: '\e92b';
}
.icon-menu3_valuation:before {
  content: '\e92c';
}
.icon-menu3_watchlist:before {
  content: '\e92d';
}
.icon-menu3_chartbit:before {
  content: '\e92e';
}
.icon-menu3_chat:before {
  content: '\e92f';
}
.icon-menu3_home:before {
  content: '\e930';
}
.icon-menu3_profile:before {
  content: '\e931';
}
.icon-toolbar_delete:before {
  content: '\e96e';
}
.icon-toolbar_note:before {
  content: '\e96f';
}
.icon-toolbar_error:before {
  content: '\e96d';
}
.icon-toolbar_next:before {
  content: '\e968';
}
.icon-toolbar_prev:before {
  content: '\e969';
}
.icon-toolbar_up:before {
  content: '\e96a';
}
.icon-toolbar_down:before {
  content: '\e96b';
}
.icon-toolbar_bell:before {
  content: '\e963';
}
.icon-toolbar_bell-fill:before {
  content: '\e964';
}
.icon-toolbar_check:before {
  content: '\e961';
}
.icon-toolbar_plus:before {
  content: '\e962';
}
.icon-toolbar_copy:before {
  content: '\e943';
}
.icon-setting_switch:before {
  content: '\e919';
}
.icon-toolbar_picture:before {
  content: '\e914';
}
.icon-toolbar_expand:before {
  content: '\e913';
}
.icon-toolbar_fundachart:before {
  content: '\e942';
}
.icon-toolbar_rounddown:before {
  content: '\e944';
}
.icon-toolbar_roundup:before {
  content: '\e945';
}
.icon-toolbar_sortdown:before {
  content: '\e946';
}
.icon-toolbar_sortup:before {
  content: '\e947';
}
.icon-setting_billing:before {
  content: '\e948';
}
.icon-setting_help:before {
  content: '\e949';
}
.icon-setting_logout:before {
  content: '\e94a';
}
.icon-setting_profile:before {
  content: '\e94b';
}
.icon-setting_rules:before {
  content: '\e94c';
}
.icon-setting_setting:before {
  content: '\e94d';
}
.icon-setting_subscribe:before {
  content: '\e94e';
}
.icon-setting_tutorial:before {
  content: '\e94f';
}
.icon-social_facebook:before {
  content: '\e950';
}
.icon-menu_calendar:before {
  content: '\e900';
}
.icon-menu_earning:before {
  content: '\e901';
}
.icon-menu_ebook:before {
  content: '\e902';
}
.icon-menu_glossary:before {
  content: '\e903';
}
.icon-menu_chartbit:before {
  content: '\e904';
}
.icon-menu_chat:before {
  content: '\e905';
}
.icon-menu_fundachart:before {
  content: '\e908';
}
.icon-menu_home:before {
  content: '\e909';
}
.icon-menu_profile:before {
  content: '\e90a';
}
.icon-menu_screener:before {
  content: '\e90b';
}
.icon-menu_seasonality:before {
  content: '\e90c';
}
.icon-menu_sector:before {
  content: '\e90d';
}
.icon-menu_valuation:before {
  content: '\e90e';
}
.icon-menu_watchlist:before {
  content: '\e9e7';
}
.icon-community:before {
  content: '\ea11';
}
.icon-briefcase_arrow:before {
  content: '\ea12';
}
